'use client';

import LottieAnimation from './lottie-animation';
import successAnimationData from '@/assets/data/success-animation.json';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from '@/components/ui/dialog';

type Props = {
  open: boolean;
  onClose: () => void;
};

function SubmittedDialog(props: Props) {
  return (
    <Dialog open={props.open} onOpenChange={props.onClose}>
      <DialogContent className="flex flex-col items-center rounded-xl md:max-w-[350px]">
        <LottieAnimation
          animationData={successAnimationData}
          height="192px"
          width="192px"
        />
        <div className="space-y-2 text-center">
          <DialogTitle className="text-xl font-bold text-neutral-90">
            Gửi thành công
          </DialogTitle>
          <DialogDescription className="text-sm">
            Chúng tôi sẽ liên hệ với bạn sớm nhất
          </DialogDescription>
        </div>
        <DialogFooter className="mt-4 w-full">
          <Button
            type="button"
            className="h-12 w-full rounded bg-primary-50 px-12 py-4 text-lg font-bold text-white"
            onClick={props.onClose}
          >
            Đóng
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default SubmittedDialog;
