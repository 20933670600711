import type { DefaultQueryError } from '@ahm/api-wrappers-core';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import {
  type AcquireMerchantResponse,
  type AcquireMerchantRequestBody,
  acquireMerchantFn,
} from './ahafood';

export const useAcquireMerchant = (
  opts?: UseMutationOptions<
    AcquireMerchantResponse,
    DefaultQueryError,
    AcquireMerchantRequestBody
  >
) =>
  useMutation<
    AcquireMerchantResponse,
    DefaultQueryError,
    AcquireMerchantRequestBody
  >({
    mutationFn: acquireMerchantFn,
    ...opts,
  });
